/* eslint-disable react/prop-types */
import React from 'react'

const Pagination = ({ totalPosts, postsPerPage, setCurrentPage, currentPage }) => {
  const pages = []

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pages.push(i)
  }
  return (
    <div className='w-full p-12 overflow-auto text-center flex gap-5  '>
        {
            pages.map((page, index) => {
              return <button onClick={() => setCurrentPage(page)} className={`${currentPage === page ? 'text-white bg-booty border-2' : 'text-booty  bg-white border-2 border-booty'} w-10 h-10 p-4  items-center justify-center flex  rounded-full`} key={index}>{page}</button>
            })
        }
    </div>
  )
}

export default Pagination
